import { initShowmore } from '../common/common.utils';
const COMPONENT_SELECTOR = 'characteristicsEnrichment';
const SHOW_MORE_TRIGGER_SELECTOR = 'js-showmore-trigger';
class CharacteristicsEnrichment extends lm.Composant {
  constructor(id) {
    super(id);
  }
}
lm.DOMReady(function () {
  new CharacteristicsEnrichment('characteristicsEnrichment');
  initShowmore(COMPONENT_SELECTOR, SHOW_MORE_TRIGGER_SELECTOR);
});